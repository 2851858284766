import * as React from 'react';
import { Button, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import Table from '../../parts/Table/Table';
import api from "../../utils/api";
import { toast } from 'react-toastify';
import TypesModal from './NodesModal';
import './Nodes.css'
import { NODE_TYPE_ENUM } from './NodeTypes';
import DeleteRow from '../../parts/DeleteRow';
import { useParams } from 'react-router-dom';
import TocIcon from '@mui/icons-material/Toc';
import PostAddIcon from '@mui/icons-material/PostAdd';
import DeleteShow from '../DeleteShow';

const _nodeCells = [
    {
        field: 'id',
        label: 'ID',
        filter: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
    },
    {
        field: 'url',
        label: 'URL',

    },
    {
        field: 'status',
        label: 'Status',
        cellRenderer: ({ data: { status } }) => NODE_TYPE_ENUM[status]
    },
];

const Templates = React.memo(() => {
    const [nodeCells, setNodeCells] = useState([])
    const [isOpenNodeCreate, setIsOpenNodeCreate] = useState(false)
    const [isOpenNodeEdit, setIsOpenNodeEdit] = useState(false)
    const [isEditable, setIsEditable] = useState(false)
    const [nodeRows, setNodeRows] = useState([])
    const [selectedRows, setSelectedRows] = useState({});
    const [isValidName, setValidTitle] = useState(true)
    const [isValidType, setValidType] = useState(true)
    const [nodeRow, setNodeRow] = useState({})
    const [rows, setRows] = useState({});
    const handleCreateNodePanel = () => {
        setIsOpenNodeCreate(!isOpenNodeCreate)
    }

    console.log(nodeRow)



    const fetchNodes = (cb) => {
        api.get('/nodes').then(({ nodes }) => {
            setNodeRows(nodes);
            cb && cb()
        }).catch(console.log)
    }

    useEffect(() => {
        fetchNodes();
        setNodeCells(_nodeCells)
    }, [])

    const handleEditNodePanel = ({ data: _row }) => {
        setIsOpenNodeEdit(!isOpenNodeEdit)
        setIsOpenNodeCreate(false)
        setNodeRow(_row)
    }

    const handleNodeCreate = (event) => {
        event.preventDefault()
        const { target: { url: urlValue, status: statusValue } } = event

        const url = urlValue.value;
        const status = +statusValue.checked;

        if (isValidName && isValidType) {
            api.post('/nodes', {
                url,
                status
            }).then(() => {
                fetchNodes()
                handleCreateNodePanel()
                toast.success("Succes!")
            }).catch(() => {
                toast.error("Error!");
            })
        }
    }


    const handleNodeEdit = (event) => {
        event.preventDefault()
        const { target: { url: urlValue, status: statusValue } } = event

        const url = urlValue.value;
        const status = +statusValue.checked;

        if (isValidName && isValidType) {
            api.put(`/nodes/${nodeRow.id}`, {
                url,
                status
            }).then(() => {
                fetchNodes()
                handleEditNodePanel()
                toast.success("Succes!")
            }).catch(() => {
                toast.error("Error!");
            })
        }
    }


    return <Box className='cars'>
        <Box className='import'>
            <Box className='importIcon'><PostAddIcon fontSize='large' /></Box>
            <Box className='importName'>
                <Typography variant='h5'>Add Nodes</Typography>
            </Box>
        </Box>
        <Box className='typesWrapper'>
            <Box className='contactWrapper'>
                <Box className='typeWrapper'>
                    <Typography className='typeText'>Nodes</Typography>
                </Box>
                <Box className='table-wrapper'>
                    <DeleteShow
                        fetchUsers={fetchNodes}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        cars={rows}
                        setCars={setRows} />
                    <Button color='inherit' className='createButton' onClick={handleCreateNodePanel} variant='outlined'
                    >Add
                    </Button>
                    <Table className='contactTable'
                        showCheckBox={false}
                        rows={nodeRows}
                        cells={nodeCells}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        isConfigurable={false}
                        rowClick={handleEditNodePanel}
                        onFilterChange={fetchNodes}
                        id='nodes'
                    />
                    {isOpenNodeCreate ? <TypesModal
                        title="Add Node"
                        create={handleCreateNodePanel}
                        submit={handleNodeCreate}
                        button="Add"
                        nodeRow={nodeRow}
                    /> : null}
                    {isOpenNodeEdit ? <TypesModal
                        title="Edit Type"
                        create={handleEditNodePanel}
                        submit={handleNodeEdit}
                        name={nodeRow.url}
                        button="Edit"
                        isEditable={true}
                        _nodeCells={_nodeCells}
                        nodeRow={nodeRow}
                    /> : null}
                </Box>
            </Box>
        </Box>
    </Box>
});

export default Templates;
