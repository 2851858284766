import * as React from "react";
import { useCallback, useEffect, useRef } from "react"; // Optional theme CSS
import Paper from "@mui/material/Paper";
import "./Table.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css";

LicenseManager.setLicenseKey(
  process.env.REACT_APP_AG_GRID + "6375ce4e397ec3b0caec647570d74f25"
);

export default function ReactVirtualizedTable(props) {
  const {
    rows = [],
    cells: _cells = [],
    setSelectedRows = () => {},
    selectedRows = [],
    rowClick = () => {},
    rowDoubleClicked = () =>{},

    onDelete = () => {},
    onFilterChange = () => {},
    id,
    height = "300px",
  } = props;
  const gridRef = useRef();

  useEffect(() => {
    onFilterChange(()=>{
      gridRef.current.api.sizeColumnsToFit()
    });
    
  }, []);

  useEffect(() => {
    if (gridRef && selectedRows.length)
      gridRef.current.api.forEachNode((node) =>
        node.setSelected(!!node.data && selectedRows.includes(node.data.id))
      );
  }, [selectedRows]);

  const onSelectionChanged = useCallback((e) => {
    const selectedCars = e.api.getSelectedNodes().map((r) => r.data.id);
    setSelectedRows(selectedCars);
  }, []);
  return (
    <Paper style={{ height }}>
      <AgGridReact
        ref={gridRef}
        className="ag-theme-alpine-dark"
        columnDefs={_cells}
        rowData={rows}
        rowGroup={true}
        onRowDoubleClicked={rowDoubleClicked}
        onRowClicked={rowClick}
        rowSelection={"multiple"}
        suppressRowClickSelection={true}
        onSelectionChanged={onSelectionChanged}
        suppressSizeToFit={true}
      />
    </Paper>
  );
}
