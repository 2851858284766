export const SETTING_TYPE_ENUM = {
    1: 'DMS',
    2: 'ACRA',
    3: 'NORK',
    'DMS': 1,
    'ACRA': 2,
    'NORK': 3,
}

export const SETTING_TYPE_LIST = [
    {
        id:1,
        label: 'DMS'
    },
    {
        id:2,
        label: 'ACRA'
    },
    {
        id:3,
        label: 'NORK'
    }
]