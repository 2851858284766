import * as React from "react";
import {Link} from "react-router-dom";
import Typography from '@mui/material/Typography';
const LinkComponent = ({to, text, sx})=> (<Link to={to} style={{
    width: '100%',
    textDecoration: 'none',
    fontWeight: 'bold',
    ...sx}}>
    <Typography variant='span'>{text}</Typography>
    </Link>);

export default LinkComponent;