import * as React from 'react';
import './Campaign.css'
import BadgeIcon from '@mui/icons-material/Badge';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SensorsIcon from '@mui/icons-material/Sensors';
import CachedIcon from '@mui/icons-material/Cached';
import Table from '../../parts/Table/Table';
import { Button, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {LinearProgress} from '@mui/material';
import { CAMPAIGN_STATUS , CAMPAIGN_ACRA_TYPE, CAMPAIGN_NORK_TYPE } from './CampaignTypes';
import { color } from '@mui/system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from "../../utils/api";
import { ContactSupportOutlined } from '@mui/icons-material';



export default function CampaignProgres(props) {

    const [campaignRows, setCampaignRows] = React.useState([])
    const [progress, setProgress] = React.useState();
    const { id } = useParams()

 function LinearProgressWithLabel(props) {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ width: '100%', mr: 1 }}>
            <LinearProgress variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            <Typography variant="body2">{`${Math.round(
              props.value,
            )}%`}</Typography>
          </Box>
        </Box>
      );
            }

    const fetchStatus = () => {
        api.get('/campaigns').then(({ campaigns }) => {
            setCampaignRows(campaigns);
        }).catch(console.log)
    }

    const fetchProgress = () => {
        api.get(`/campaigns/${id}`).then((status) => {
            if(status.completedItemsCount > 0 && status.itemsCount > 0){
            setProgress((status.completedItemsCount * 100) / status.itemsCount)
            } else {
                setProgress('0')
            }
        }).catch(console.log)
    }
    useEffect(() => {
        fetchProgress()
    }, [id])

    const handleEditStatus = (event) => {
        event.preventDefault()

    let status = 1;
        
        if(props.status == 1) {
            status = 2
        }

        api.patch(`/campaigns/${id}`, {
            status
        }).then(() => {
            props.fetchCampaign(id)
            toast.success("Succes!")
        }).catch(() => {
            toast.error("Error!");
        })
    }

  return (
    <Box className='progressWrapper'>
                    <Box className='progress'><LinearProgressWithLabel  variant="determinate" value={progress} /><CachedIcon onClick={LinearProgressWithLabel} sx={{cursor: 'pointer', color: 'white'}} color='primary'/></Box>
                <div style={{
                    display:'flex',
                    justifyContent:'space-between'
                }}>
                    <Button onClick={handleEditStatus} className='propgressButton' variant='outlined' color={(props.status) == 1 ? 'inherit' : 'error'}>{(props.status) == 1 ? 'ACTIVATE' : 'PAUSE'}</Button>
                    <a href={`${process.env.REACT_APP_API_URI}/api/campaigns/export/${id}`} target='_blank' style={{
                        color:'#fff'
                    }}>Export</a>
                </div>
                </Box>
  )

}