import * as React from 'react';
import './Campaign.css'
import BadgeIcon from '@mui/icons-material/Badge';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import SensorsIcon from '@mui/icons-material/Sensors';
import CachedIcon from '@mui/icons-material/Cached';
import Table from '../../parts/Table/Table';
import { Button, TextField, Typography } from '@mui/material';
import { toast } from 'react-toastify';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { LinearProgress } from '@mui/material';
import { CAMPAIGN_STATUS, CAMPAIGN_ACRA_TYPE, CAMPAIGN_NORK_TYPE } from './CampaignTypes';
import { color } from '@mui/system';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import api from "../../utils/api";
import { ContactSupportOutlined } from '@mui/icons-material';
import CampaignProgres from './CampaignProgres';
import Editor, { DiffEditor, useMonaco, loader } from "@monaco-editor/react";
import CallMissedIcon from '@mui/icons-material/CallMissed';

const CAMPAIGN_ITEM_STATUS = {
    0: 'NEW',
    1: 'USED',
    'NEW': 0,
    'USED': 1,
}
const headCells = [
    {
        field: 'id',
        label: 'ID',
    },
    {
        field: 'ssn',
        label: 'SSN',
    },
    {
        field: 'acra',
        label: 'Acra',
        cellRenderer: ({ data: { acra }}) => CAMPAIGN_ACRA_TYPE[acra]
    },
    {
        field: 'nork',
        label: 'Nork',
        cellRenderer: ({ data: { nork }}) => CAMPAIGN_NORK_TYPE[nork]
    },
    {
        field: 'status',
        label: 'Status',
        cellRenderer: ({ data: { status }}) => CAMPAIGN_ITEM_STATUS[status]
    },
    {
        field: 'createdAt',
        label: 'Created At',
        cellRender: ({ data: { createdAt }}) => {
            return new Date(createdAt).getDay() + '/' + new Date(createdAt).getMonth() + '/' + new Date(createdAt).getFullYear() + ' '
                + new Date(createdAt).getHours() + ':' + new Date(createdAt).getMinutes() + ':' + new Date(createdAt).getSeconds()

        }
    },
];

export default function UserInfo(props) {

    const [openInfo, setOpenInfo] = React.useState(false)
    const [row, setRow] = React.useState({})


    const fetchItem = (row_id) => {
        api.get(`/campaigns/item/${row_id}`).then((item) => {
            item.acra.sort((a,b) => b.id-a.id)
            item.dms.sort((a,b) => b.id-a.id)
            item.nork.sort((a,b) => b.id-a.id)
            setRow(item);
        }).catch(console.log)
    }

    const handleOpenInfo = (_row) => {
        if (!openInfo){
            fetchItem(_row.data.id)
        } else {
            setRow({})
        }
        setOpenInfo(!openInfo)
    }
    console.log('fetchItem',row?.id);
    return <div className='parentsInfo'>
        <Box className='parentInfo'>
            <Box className='info'>
                <Box>
                    <Typography variant='h4' className='details'>Campaign Details</Typography>
                </Box>
                <Box className='childParent'>

                    <Box className='infoChilds'>
                        <Box className='userIcons'><BadgeIcon fontSize='small' /><Box className='infoName'>Id</Box></Box> <Typography className='id'>{props.campaign.id}</Typography>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><PermIdentityIcon fontSize='small' /><Box className='infoName'>Name</Box></Box><Box className='id'>{props.campaign.name}</Box>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><PermIdentityIcon fontSize='small' /><Box className='infoName'>Acra</Box></Box> <Box className='id'>{(props.campaign.acra) == 1 ? 'Yes' : 'No'}</Box>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><PermIdentityIcon fontSize='small' /><Box className='infoName'>Nork</Box></Box> <Box className='id'>{(props.campaign.nork) ? 'Yes' : 'No'}</Box>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><BadgeIcon fontSize='small' /><Box className='infoName'>Tree ID</Box></Box> <Box className='id'>{props.campaign.treeId}</Box>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><BorderColorIcon fontSize='small' /><Box className='infoName'>Product Types</Box></Box> <Box className='id'>{props.campaign.productTypes}</Box>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><CalendarMonthIcon fontSize='small' /><Box className='infoName'>Start Date</Box></Box> <Box className='id'>{props.campaign.startDateTime}</Box>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><AccessTimeIcon fontSize='small' /><Box className='infoName'>Duration</Box></Box> <Box className='id'>{props.campaign.duration}</Box>
                    </Box>
                    <Box className='infoChilds'>
                        <Box className='userIcons'><SensorsIcon fontSize='small' /><Box className='infoName'>Status</Box></Box> <Box className='id'>{(props.campaign.status) == 1 ? 'DRAFT' : 'ACTIVE'}</Box>
                    </Box>
                </Box>
            </Box>
            <CampaignProgres status={props.campaign.status} fetchCampaign={props.fetchCampaign} />
        </Box>
        {openInfo ? <Box className='overlead'>

            <Box className='form' component="form" onSubmit={props.submit}>

                <Button className='modalCloseButton' color='inherit' onClick={handleOpenInfo} variant="text">
                    X
                </Button>
                <Typography variant='h5'>Campaign Item </Typography>
                <Box className='inputs'>
                    <Typography><BadgeIcon className='itemsSvg' fontSize='small' />ID: {row.id}</Typography>
                    <Typography><BorderColorIcon className='itemsSvg' fontSize='small' />SSN: {row.ssn}</Typography>
                    <Typography><SensorsIcon className='itemsSvg' fontSize='small' />Status: {row.status}</Typography>
                    <Typography><CalendarMonthIcon className='itemsSvg' fontSize='small' />Created Date: {row.createdAt}</Typography>
                    <Box className='rowsWrapper'>
                        <Box>
                            {row?.dms?.[0] ?
                                (<><Typography variant='h5'>DMS</Typography>
                                    <Typography><BadgeIcon className='itemsSvg' fontSize='small' />ID: {row.dms[0].id}</Typography>
                                    <Typography><CalendarMonthIcon className='itemsSvg' fontSize='small' />Created Date: {row.dms[0].createdAt}</Typography>
                                    <Typography><Editor
                                        height="20vh"
                                        defaultLanguage="json"
                                        autoIdent={true}
                                        onMount={(e)=>{
                                            setTimeout(() => {
                                                e.getAction('editor.action.formatDocument').run();
                                            }, 100)
                                        }}
                                        defaultValue={row.dms[0].response}
                                    /></Typography></>) : (<Typography variant='h5'>DMS:  -</Typography>)}
                        </Box>
                        <Box>
                            {row?.acra?.[0] ?
                                (<><Typography variant='h5'>ACRA</Typography>
                                    <Typography><BadgeIcon className='itemsSvg' fontSize='small' />ID: {row.acra[0].id}</Typography>
                                    <Typography><CalendarMonthIcon className='itemsSvg' fontSize='small' />Created Date: {row.acra[0].createdAt}</Typography>
                                    <Typography>
                                        <CallMissedIcon className='itemsSvg' fontSize='small' />Response: <Editor
                                        height="20vh"
                                        defaultLanguage="json"
                                        onMount={(e)=>{
                                            setTimeout(() => {
                                                e.getAction('editor.action.formatDocument').run();
                                            }, 100)
                                        }}
                                        defaultValue={row.acra[0].response}
                                    /></Typography></>) : (<Typography variant='h5'>ACRA:  -</Typography>)}
                        </Box>
                        <Box>
                            {row?.nork?.[0] ?
                                (<><Typography variant='h5'>NORK</Typography>
                                    <Typography><BadgeIcon className='itemsSvg' n fontSize='small' />ID: {row.nork[0].id}</Typography>
                                    <Typography><CalendarMonthIcon className='itemsSvg' fontSize='small' />Created Date: {row.nork[0].createdAt}</Typography>
                                    <Typography><CallMissedIcon className='itemsSvg' fontSize='small' />Response: <Editor
                                        height="20vh"
                                        defaultLanguage="json"
                                        onMount={(e)=>{
                                            setTimeout(() => {
                                                e.getAction('editor.action.formatDocument').run();
                                            }, 100)
                                        }}
                                        defaultValue={row.nork[0].response}
                                    /></Typography></>) : (<Typography variant='h5'>NORK:  -</Typography>)}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box> : null}
        <Table
            cells={headCells}
            rows={props.campaign.items}
            showCheckBox={false}
            id='campaign1'
            rowClick={handleOpenInfo} />
    </div>
}