import { useContext, useEffect, useState } from 'react';
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';


const PublicWrapper = (props) => {
    const navigate = useNavigate()
    const userContext = useContext(UserContext)

    useEffect(() => {
        if (userContext.user === false) {
            return navigate('/login')
        }
    }, [userContext.user]);

    return (
        <>
            <div className='page'>
                    {props.children}
                </div>
        </>
    );
}

export default PublicWrapper;