import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";
import "./DeleteShow.css";
import api from "../utils/api";
import { Sync } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const DeleteShow = ({
  selectedRows,
  setSelectedRows,
  cars,
  setCars,
  fetchUsers,
}) => {
  const [loading, setLoading] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleAswa = () => {
    toast.info('Start "ASWA Check" JOB, it will take few minutes');
    setLoading(true);
    const plateNumbers = cars
      .filter((c) => selectedRows.includes(c.id))
      .map((c) => c.plateNumber);
    api
      .post("/cars/get-cars-data", {
        carNumbers: plateNumbers,
      })
      .then(() => {
        toast.success("ASWA Check is Done");
      })
      .catch((e) => {
        if (e.code !== "ECONNABORTED") {
          toast.error("ASWA does not respond");
        }
      })
      .finally(() => {
        setLoading(false);
        fetchUsers();
      });
  };

  const handleBonusMalus = () => {
    setLoading(true);
    toast.info('Start "Bonus Malus"  JOB, it will take few minutes');
    const plateNumbers = cars
      .filter((c) => selectedRows.includes(c.id))
      .map((c) => c.plateNumber);
    api
      .post("/cars/bonus-malus", {
        carNumbers: plateNumbers,
      })
      .then((errors) => {
        if (!errors.length) {
          // setSelectedRows([]);
          toast.success("Bonus Malus is Done");
        } else {
          const errorCarNumbers = errors.map((car) => car.carNumber);
          toast.info(
            "Bonus Malus is Done.\n\r  Problem with \n\r " +
              errorCarNumbers.join(",\n\r") +
              ".try to get data from aswa."
          );
        }
      })
      .catch((e) => {
        if (e.code !== "ECONNABORTED") {
          toast.error("ASWA does not respond");
        }
      })
      .finally(() => {
        setLoading(false);
        fetchUsers();
      });
  };

  const handleContractPrice = () => {
    setLoading(true);
    toast.info('Start "Contract Price"  JOB, it will take few minutes');
    const plateNumbers = cars
      .filter((c) => selectedRows.includes(c.id))
      .map((c) => c.plateNumber);
    api
      .post("/cars/contract-price", {
        carNumbers: plateNumbers,
      })
      .then((errors) => {
        if (!errors.length) {
          toast.success("Contract Price is Done");
        } else {
          const errorCarNumbers = errors.map((car) => car.carNumber);
          toast.info(
            "Contract Price is Done.\n\r  Problem with \n\r " +
              errorCarNumbers.join(",\n\r") +
              ".try to get data from aswa."
          );
        }
      })
      .catch((e) => {
        if (e.code !== "ECONNABORTED") {
          toast.error("ASWA Price not respond");
        }
      })
      .finally((e) => {
        console.log(e);
        setLoading(false);
        fetchUsers();
      });
  };

  const handleCreateCampaign = () => {
    setLoading(true);
    api
      .post("/campaign/create", {
        name: "Campaign",
        campaignCars: selectedRows,
      })
      .then(({ id }) => {
        // setSelectedRows([]);
        navigate(`/car-campaign/${id}`);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const openDeletePanel = () => {
    setOpen(!isOpen);
  };

  const handleDelete = () => {
    setLoading(true);
    const ids = cars
      .filter((c) => selectedRows.includes(c.id))
      .map((c) => c.id);
    api
      .post("/cars/remove", {
        cars: ids,
      })
      .then(() => {
        const newCarsList = cars.filter((c) => !selectedRows.includes(c.id));
        setCars(newCarsList);
        setSelectedRows([]);
        toast.success("Deleted successfully");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Box className={`CarsActions ${selectedRows.length ? "show" : ""}`}>
      <Box className="buttonParentDiv">
        {loading ? <Sync className="rotate" /> : null}
        <Box className="firstButtonDiv">
          <Button
            disabled={loading}
            className="applyButton"
            variant="contained"
            color="error"
            size="small"
            onClick={openDeletePanel}
          >
            Delete
          </Button>
          {isOpen ? (
            <Box className="overlead">
              <Box className="logOutForm" sx={{ borderRadius: "5px" }}>
                <Typography>
                  Are you sure, you want to delete this item?
                </Typography>
                <Box className="LogOutPanelButtons">
                  <Button
                    color="error"
                    onClick={handleDelete}
                    sx={{ width: "25%", margin: "20px" }}
                    variant="contained"
                  >
                    Yes
                  </Button>
                  <Button
                    color="inherit"
                    type="submit"
                    onClick={(event) => {
                      event.stopPropagation();
                      setOpen(!isOpen);
                    }}
                    sx={{ color: "black", width: "25%", margin: "20px" }}
                    variant="contained"
                  >
                    No
                  </Button>
                </Box>
              </Box>
            </Box>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};
export default DeleteShow;
