export const NODE_TYPE_ENUM = {
    1: 'ACTIVE',
    0: 'INACTIVE',
    'ACTIVE': 1,
    'INACTIVE': 0,
}

export const NODE_TYPE_LIST = [
    {
        id:1,
        label: 'ACTIVE'
    },
    {
        id:0,
        label: 'INACTIVE'
    }
]