import * as React from 'react';
import { useContext, useEffect, useState } from 'react' ;
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useNavigate, } from 'react-router-dom';
import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import { UserContext } from '../../context/UserContext';
import * as Validator from "../../utils/validation";
import Link from "../../parts/Link";
import './Auth.css'
import api from "../../utils/api";


export default function SignIn() {
    const [isValidEmail, setValidEmail] = useState(true)
    const [isValidPassword, setValidPassword] = useState(true)

    const { setUser, user } = useContext(UserContext);

    const navigate = useNavigate()

    useEffect(() => {
        if (user) {
            navigate('/campaigns')
        }
    }, [user])

    const handleSubmit = (event) => {
        event.preventDefault()
        const { target: { email: emailValue, password: passWordValue } } = event

        const email = emailValue.value;
        const password = passWordValue.value;

        if (isValidEmail && isValidPassword && email && password) {
            api.post('/login', {
                email,
                password
            }).then((data) => {
                setUser(data)
                navigate('/campaigns')
            }).catch((error) => {
                console.log(error)
                toast.error(error.response.data.message);
            })
        }
    }

    const checkValidEmail = ({ target }) => setValidEmail(Validator.isValidEmail(target.value));

    const checkValidPassword = ({ target }) => setValidPassword(Validator.lengthMoreThan(target.value, 7))

    return (
        <>
            <div className='parentDiv'>
                <img className='authIcon' src='/images/armenia-logo-min-original.png'></img>
                <div className='childDiv'>
                        <Container component="main" maxWidth="xs">
                            <Box className='parentForm'                           >
                                <Box component="form" onSubmit={handleSubmit} noValidate>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="Email"
                                        name="email"
                                        autoComplete="email"
                                        error={!isValidEmail}
                                        onBlur={(e) => checkValidEmail(e)}
                                    />
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="password"
                                        label="Password"
                                        type="password"
                                        id="password"
                                        autoComplete="password"
                                        error={!isValidPassword}
                                        onBlur={(event) => checkValidPassword(event)}
                                    />
                                    <div className='loginLinkDiv'>
                                        <FormControlLabel className='formController'
                                            control={
                                                <Checkbox value="remember" color="primary" />}
                                            label="Remember me"
                                        />
                                        <Grid className='parentGrid'
                                            container>
                                            <Grid className='childGrid'
                                                item xs>
                                                <Link sx={{color: 'white'}} to="/forgot"
                                                    text='Forgot Password?'
                                                ></Link>

                                            </Grid>
                                            {/*<Grid className='childGrid'*/}
                                            {/*    item xs>*/}
                                            {/*    <Link to="/register"*/}
                                            {/*        text='Register?'*/}
                                            {/*    ></Link>*/}
                                            {/*</Grid>*/}
                                        </Grid>
                                    </div>
                                    <Button className='loginButton'
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color='inherit'
                                    >
                                        Login
                                    </Button>

                                </Box>
                            </Box>
                        </Container>
                </div>
            </div>
        </>
    );
}
