import * as React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import './CampaignPanel.css'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import api from "../../utils/api";
import { useEffect } from "react";
import { toast } from 'react-toastify';
import Switch from '@mui/material/Switch';
import { UserContext } from '../../context/UserContext';
import Stack from '@mui/material/Stack';
import { useParams } from 'react-router-dom';

const EditCampaignPanel = React.memo((props) => {
    const {
        onSubmit = () => {
        },
        onClose = () => {
        },
        isEdit = false,
        duration: _duration = {},
        acra: _acra,
        startDateTime: _startDateTime,
        endTime: _endTime,
        name: _name,
        treeId: _treeId,
        productTypes: _productTypes,
        nork: _nork,
        status: _status,
        failedRows: _failedRows,
        headCells
    } = props;
    const [acra, setAcra] = React.useState(props.campaignRow?.acra)
    const [nork, setNork] = React.useState(props.campaignRow?.nork)
    const [startDateTime, setStartDateTime] = React.useState(props.campaignRow?.startDateTime)
    const [startDate, setStartDate] = React.useState(
        (props.campaignRow?.startDateTime || new Date().toISOString()).slice(0, 10)
      );
      const [startTime, setStartTime] = React.useState(
        (props.campaignRow?.startDateTime || new Date().toISOString()).slice(11, 16)
      );
    const [treeId, setTreeId] = React.useState(props.campaignRow?.treeId)
    const [productTypes, setProductTypes] = React.useState(props.campaignRow?.productTypes)
    const [duration, setDuration] = React.useState(props.campaignRow?.duration)
    const [failedRows, setFailedRows] = React.useState(props.campaignRow?.failedRows)
    const [status, setStatus] = React.useState(props.campaignRow?.status == 2 ? true : false)
    const [name, setName] = React.useState(props.campaignRow?.name)
    const [print, setPrint] = React.useState(false)
    const [files, setFiles] = React.useState(false)
    const [campaign, setCampaign] = React.useState({})

    const { id } = useParams()

    const handelFileChange = (event) => {
        setPrint(event.target.value)
        setFiles(event.target.files)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        const headers = {
            'Content-Type': 'multipart/form-data'
        };

        const body = new FormData();
        body.append('file', files[0])
        body.append('duration', duration)
        body.append('name', name)
        body.append('acra', acra)
        body.append('nork', nork)
        body.append('startDateTime',startDate.concat(' ' + startTime))
        body.append('treeId', treeId)
        body.append('productTypes', productTypes)
        body.append('failedRows', failedRows)
        body.append('status', status ? 2 : 1)
        const apiInstance = isEdit ? api.put : api.post
        const apiUrl = isEdit ? `/campaigns/${props.campaignRow.id}` : '/campaigns'
        apiInstance(apiUrl, body, {
            headers,
        }).then(() => {
            toast.success("Success!");
            onClose()
            props.fetchCampaigns()
        }).catch((e) => {
            toast.error("Error!");
        })
    };

    const handleChange = () => {
        setStatus(!status)
    }

    function datetimeLocal(datetime = new Date()) {
        const dt = new Date(datetime);
        dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
        console.log(dt.toISOString().slice(0, 16))
        return dt.toISOString().slice(0, 16);

    }
    return <Box className='overlead'>

        <Box className='form campaign-form' component="form" onSubmit={handleSubmit}>
            <Button color='inherit' className='modalCloseButton' onClick={() => onClose()} variant="text">
                X
            </Button>
            <Typography variant='h5' className='infoName'>{props.title}</Typography>
            <Box className='inputsWrapper'>
                <Box className='inputs-container'>
                    <Box>
                        <Box className='row'>
                            <Box>
                                <Typography variant='h6' className='infoName'>Campaign Name</Typography>
                                <TextField
                                    size='small'
                                    required
                                    name='name'
                                    defaultValue={props.campaignRow?.name}
                                    sx={{ width: '100%' }}
                                    value={name} onChange={({ target }) => setName(target.value)} variant="outlined" />
                            </Box>
                        </Box>
                        <Box className='row'>
                            <Box>
                                <Typography variant='h6' className='infoName'>Tree Id</Typography>
                                <TextField
                                    size='small'
                                    name='treeId'
                                    required
                                    defaultValue={props.campaignRow?.treeId}
                                    sx={{ width: '100%' }}
                                    value={treeId} onChange={({ target }) => setTreeId(target.value)} variant="outlined" />
                            </Box>
                        </Box>
                        <Box className='row'>
                            <Box>
                                <Typography variant='h6' className='infoName'>Product Types</Typography>
                                <TextField
                                    size='small'
                                    name='productTypes'
                                    required
                                    defaultValue={props.campaignRow?.productTypes}
                                    sx={{ width: '100%' }}
                                    value={productTypes} onChange={({ target }) => setProductTypes(target.value)} variant="outlined" />
                            </Box>
                        </Box>
                        <Box className='row'>
                            <Typography variant='h6' className='infoName'>Start Date</Typography>
                            {/* <TextField
                                size='small'
                                className='time'
                                type="datetime-local"
                                required
                                defaultValue={datetimeLocal(props.campaignRow?.startDateTime)}
                                name='startDateTime'
                                value={datetimeLocal(startDateTime)}
                                onChange={({ target }) => setStartDateTime(target.value)}
                                sx={{ width: '100%', color: 'white' }}
                            /> */}
                            <TextField
                                className="time"
                                type="date"
                                name="startDate"
                                value={startDate}
                                onChange={({ target }) => setStartDate(target.value)}
                                defaultValue={datetimeLocal(props.campaignRow?.startDateTime).slice(0, 10)}
                                inputProps={{
                                    min: new Date().toISOString().slice(0, 10),
                                }}
                                sx={{ width: "50%" }}
                            />
                            <TextField
                                className="time"
                                defaultValue={datetimeLocal(props.campaignRow?.startDateTime).slice(11, 16)}
                                type="time"
                                name="startTime"
                                value={startTime}
                                onChange={({ target }) => setStartTime(target.value)}
                                sx={{ width: "50%" }}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <Box className='row'>
                            <Box>
                                <Typography variant='h6' className='infoName'>Acra</Typography>
                                <Select
                                    size='small'
                                    name='acra'
                                    required
                                    defaultValue={props.campaignRow?.acra}
                                    placeholder="Acra"
                                    onChange={({ target }) => setAcra(target.value)}
                                    value={acra}
                                    sx={{ width: '80%' }}
                                >
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </Box>
                        </Box>
                        <Box className='row'>
                            <Box>
                                <Typography variant='h6' className='infoName'>Nork</Typography>
                                <Select
                                    size='small'
                                    name='nork'
                                    required
                                    defaultValue={props.campaignRow?.nork}
                                    placeholder="Nork"
                                    onChange={({ target }) => setNork(target.value)}
                                    value={nork}
                                    sx={{ width: '80%' }}
                                >
                                    <MenuItem value={1}>Yes</MenuItem>
                                    <MenuItem value={0}>No</MenuItem>
                                </Select>
                            </Box>
                        </Box>

                        <Box className='row'>
                            <Typography variant='h6' className='infoName'>Duration</Typography>
                            <TextField
                                size='small'
                                name='duration'
                                required
                                defaultValue={props.campaignRow?.duration}
                                placeholder="Duration"
                                onChange={({ target }) => setDuration(target.value)}
                                value={duration}
                                type='number'
                                sx={{ width: '80%' }}
                            >
                            </TextField>
                        </Box>
                        <Box className='row'>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography variant='h6' className='infoName'>File</Typography>
                                <Stack spacing={1}>
                                    <Button color='inherit' className='choose' variant="outlined" component="label">
                                        Choose file
                                        <input defaultValue={props.campaignRow?.file} name='file' hidden accept="xls/*" multiple type="file" onChange={handelFileChange} />
                                    </Button>

                                    <Typography variant='span'>
                                        {print} {props.isEditable ?
                                            <><Typography>Status</Typography>
                                                <Switch onChange={handleChange} name='status' label='label' checked={status} /></> : null}
                                    </Typography>
                                </Stack>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className='campaignButton'>
                    <Button color='inherit' type='submit' disabled={!startDate}
                                sx={{ width: '40%', margin: '20px 0', color: 'black' }} variant='contained'
                    >{props.button}</Button>
                </Box>
            </Box>
            {/* {props.isOpenEditPanel ?
                <><Typography>Status</Typography>
                    {props.headCells.status == 1 ? (<Switch label='label' />) :
                        (<Switch label='label' defaultChecked />)}</> : null} */}
        </Box>
    </Box>
})

export default EditCampaignPanel;