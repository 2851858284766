import * as React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import './NodesModal.css';
import { TEMPLATES_TYPE_LIST } from "./NodeTypes";
import { useState } from "react";
import * as Validator from "../../utils/validation";
import Switch from '@mui/material/Switch';
import { isEditable } from '@testing-library/user-event/dist/utils';
import CssBaseline from '@mui/material/CssBaseline';

export default function TypesModal(props) {

    const [isValidNumber, setValidNumber] = useState(true)

    const checkValidNumber = ({target})  => setValidNumber(Validator.isValidNumber(target.value));

    return <Box className='overlead'>

        <Box className='form' component="form" onSubmit={props.submit}>

            <Button color='inherit' className='modalCloseButton' onClick={props.create} variant="text">
                X
            </Button>
            <Typography variant='h5'>{props.title}</Typography>
            <Box className='inputsParent'>
            <Box className='formControlChild'>
                <TextField
                    size="small"
                    name='url'
                    defaultValue={props.nodeRow?.url}
                    variant="outlined"
                    placeholder='URL'
                    label='URL'
                    margin="normal"
                    sx={{
                        m: 1,
                        minWidth: 200,
                        borderRadius: '5px',
                        width: '30%',
                        margin: '0 15px 0 15px'
                    }}
                ></TextField>
                </Box>
                <Typography>Status</Typography>
                <Switch name='status' label='label' defaultChecked={!!props?.nodeRow?.status} />
            </Box>
            <Box className='tempsParent'>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button type='submit'
                    color='inherit'
                    sx={{ width: '30%', margin: '10px'}} variant='outlined'
                >{props.button}</Button>
            </Box>
        </Box>
    </Box>
}