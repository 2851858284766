export const CAMPAIGN_STATUS ={
    1: 'DRAFT',
    2: 'ACTIVE',
    3: 'USED',
    4: 'IN_PROGRESS',
    'DRAFT': 1,
    'ACTIVE': 2,
    'USED': 3,
    'IN_PROGRESS': 4,
}

export const CAMPAIGN_ACRA_TYPE = {
    No: 0,
    Yes: 1,
    0: 'No',
    1: 'Yes',
}

export const CAMPAIGN_NORK_TYPE = {
    No: 0,
    Yes: 1,
    0: 'No',
    1: 'Yes',
}