import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import './Header.css'
import logo from '../images/logo.png'

function Header ({ handleTogglePanel }) {

return ( <Box>
        <AppBar className='header' position="static">
            <Toolbar>
                <IconButton className='menuIcon'
                    onClick={handleTogglePanel}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="open drawer"
                >
                    <MenuIcon />
                </IconButton>   
                <Box className='headerIcons'>
                <a href='#'><img className='headerIcon' src={logo}></img></a>  
                </Box>   
                </Toolbar>
        </AppBar>
    </Box>)
}
export default Header