import * as React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import './Menu.css';
import LogoutIcon from '@mui/icons-material/Logout';

export default function LogOutPanel(props) {
    const [type, setType] = React.useState(1)

    return <Box className='overlead'>

        <Box className='logOutForm' component="form"
        sx={{borderRadius: '5px'}}>
            <LogoutIcon className='logOutIcon' fontSize='large'/>
            <Typography>Are you sure, you want to exit from this page?
            </Typography>
            <Box className='LogOutPanelButtons'>
            <Button color='error' type='submit' onClick={props.resetUser}
                    sx={{ width: '25%', margin: '20px' }} variant='contained'
                >Yes</Button>
            <Button color='inherit' type='submit' onClick={props.handleOpenLogOutPanel}
                    sx={{ width: '25%', margin: '20px', color: 'black' }} variant='contained'
                >No</Button>
                </Box>
        </Box>
    </Box>
}