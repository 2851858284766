import * as React from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import api from "../../utils/api";
import Table from "../../parts/Table/Table";
import { useNavigate } from 'react-router-dom';
import '../Users/User.css'
import { CAMPAIGN_STATUS, CAMPAIGN_ACRA_TYPE, CAMPAIGN_NORK_TYPE } from "./CampaignTypes";
import WidgetsIcon from '@mui/icons-material/Widgets';
import { Button, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CampaignPanel from './CampaignPanel';
import DeleteEditRow from '../../parts/DeleteEditRow';
import { toast } from 'react-toastify';
import CampaignInfo from './CampaignInfo';
import DeleteShow from '../DeleteShow';

const headCells = [
    {
        field: 'id',
        label: 'ID',
        filter: true,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        sortable: true,
    },
    {
        field: 'name',
        label: 'Name',
    },
    {
        field: 'acra',
        label: 'Acra',
        cellRenderer: ({ data: { acra }}) => CAMPAIGN_ACRA_TYPE[acra]
    },
    {
        field: 'nork',
        label: 'Nork',
        cellRenderer: ({ data:{ nork }}) => CAMPAIGN_NORK_TYPE[nork]
    },
    {
        field: 'treeId',
        label: 'Tree ID',
    },
    {
        field: 'productTypes',
        label: 'Product Types',
    },
    {
        field: 'startDateTime',
        label: 'Start Time',
        cellRender: ({ data: {startDateTime}}) => {
            return new Date(startDateTime).getDay() + '/' + new Date(startDateTime).getMonth() + '/' + new Date(startDateTime).getFullYear() + ' '
            + new Date(startDateTime).getHours() + ':' + new Date(startDateTime).getMinutes() + ':' + new Date(startDateTime).getSeconds()
                    
        }
    },
    {
        field: 'duration',
        label: 'Duration',
    },
    {
        field: 'failedRows',
        label: 'Failed Rows',
    },
    {
        field: 'status',
        label: 'Status',
        cellRenderer: ({ data: { status }}) => CAMPAIGN_STATUS[status]
    },
];

export default function Campaigns() {

    const [campaignRow, setCampaignRow] = useState([])
    const [cells, setCells] = useState([])
    const [isOpenPanel, setIsOpenPanel] = useState(false)
    const [isOpenEditPanel, setIsOpenEditPanel] = useState(false)
    const [isEditable, setIsEditable] = useState(false)
    const [isOpenInfo, setIsOpenInfo] = useState(false)
    const [selectedRows, setSelectedRows] = useState({});
    const [rows, setRows] = useState([]);
    const [campaignRows, setCampaignRows] = useState([])

    const navigate = useNavigate()

    const handleOpenInfo = ({data: row}) => {
        setIsOpenInfo(!isOpenInfo)
        navigate(`/campaigns/${row.id}`)
        setCampaignRow(row)
    }

    const handleOpenEditPanel = ( event, data) => {
        if(event){
            event.preventDefault();
            event.stopPropagation()
        }
        console.log(event)
        setCampaignRow(data)
        setIsOpenEditPanel(!!data)
    }


    const fetchCampaigns = () => {
        api.get('/campaigns').then(({ campaigns }) => {
            setRows(campaigns);
        }).catch(console.log)
    }

    useEffect(() => {
        fetchCampaigns();
        if (headCells.length !== 11) {
            headCells.push({
                field: 'delete',
                label: 'Actions',
                cellRenderer: ({data}) => {
                    return <DeleteEditRow
                        afterDelete={fetchCampaigns}
                        handleOpenEditPanel={(e) => handleOpenEditPanel( e, data)}
                        id={data.id}
                        link='/campaigns'
                    />
                }
            })
        }
        setCells(headCells)
        return () =>{
            headCells.pop()
            setCells(headCells)
        }
    }, [])

    useEffect(() => {
        api.get('/campaigns').then(({ campaigns }) => {
            setRows(campaigns);
        }).catch(console.log)
    }, [])

    const handleOpenPanel = () => {
        setIsOpenPanel(!isOpenPanel)
    }

    return <Box className='users'>
        <Box sx={{marginBottom: '80px'}} className='import'>
            <Box className='importIcon'><WidgetsIcon fontSize='large' /></Box>
            <Box className='importName'>
                <Typography variant='h5'>Campaigns</Typography>
            </Box>
        </Box>
        <Box className='table-wrapper'>
        <DeleteShow
                        fetchUsers={fetchCampaigns}
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                        cars={campaignRows}
                        setCars={setCampaignRows}
                        />
        <Button color='inherit' className='createButton' onClick={handleOpenPanel} variant='outlined'
                >Add
                </Button>
      <Table
            rows={rows}
            cells={cells}
            showCheckBox={false}
            rowDoubleClicked={handleOpenInfo}
            id='campaign'
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            onFilterChange={fetchCampaigns}
        />
        </Box>
        {isOpenPanel ? <CampaignPanel
            isEdit = {false}
            openPanel={handleOpenPanel}
            onClose={handleOpenPanel}
            title='Create Campaign'
            button='Create'
            fetchCampaigns={fetchCampaigns} /> : null}
        {isOpenEditPanel ? <CampaignPanel
            isEditable={true}
            isEdit = {true}
            openPanel={(row)=> handleOpenEditPanel(row)}
            onClose={handleOpenEditPanel}
            title='Edit Campaign'
            button='Edit'
            isOpenEditPanel={isOpenEditPanel}
            headCells={headCells}
            fetchCampaigns={fetchCampaigns}
            campaignRow={campaignRow}
        /> : null}
    </Box>
}
