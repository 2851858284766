import api from "../utils/api";
import { toast } from 'react-toastify';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { Button, TextField, Typography } from '@mui/material';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import '../pages/Users/User.css'

const DeleteRow = (props)=>{
    const onDelete = (event) => {
        event.stopPropagation()
    api.delete(`${props.link}/${props.id}`).then(() => {
        props.afterDelete();
        toast.success("Success!")
    }).catch((e) => {
        toast.error(e.response.data.error);
    })
}

    return <div className="iconsWrapper"><Button onMouseDown={props.handleOpenEditPanel} color='inherit'><span><ModeEditIcon /></span></Button>
    <Button onClick={onDelete} color="error"><span><DeleteForeverOutlinedIcon /></span></Button></div>
}

export default DeleteRow;