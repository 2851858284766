import axios from "axios";

const inst = axios.create({
    baseURL: process.env.REACT_APP_API_URI+'/api',
    timeout: 120000,
});
inst.interceptors.response.use((response) => response.data, (error) => {
    if (error?.response?.data?.statusCode === 401) {
        localStorage.removeItem('user')
        localStorage.removeItem('access_token')
        window.location.href = '/login'
    }
    return Promise.reject(error);
});

inst.interceptors.request.use(function (req) {
    req.headers = {
        ...req.headers,
        'Authorization': `Bearer ${localStorage.getItem('access_token')}`
    }
    return req;
}, (error) => Promise.reject(error));

export default inst