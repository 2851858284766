import * as React from 'react';
import Box from '@mui/material/Box';
import './Campaign.css'
import { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import api from "../../utils/api";
import { toast } from 'react-toastify';
import CampaignInfo from './CampaignInfo';


export default function Campaign() {

    const [campaign, setCampaign] = useState({})
    const { id } = useParams()

    const fetchCampaign = (_id) => {
        api.get(`/campaigns/${_id}`).then((_campaign) => {
            setCampaign(_campaign);
        }).catch(console.log)
    }

    useEffect(() => {
        fetchCampaign(id)
    }, [id])
    return <div>
        <Box className='infoParentDiv'>
            <Box className='userInfo'>
                <CampaignInfo campaign={campaign} fetchCampaign={fetchCampaign}/>
            </Box>
        </Box>
        <Box className='addContactsParent'>
            <Box className='addContactsChild'></Box>
            <Box className='addContactsChild'>
            </Box>
        </Box>
    </div>
}