import React, {useEffect, useState} from 'react';
import './App.css';
import Login from './pages/Auth/Login'
import Forgot from './pages/Auth/Forgot'
import Confirm from './pages/Auth/Confirm'
import {BrowserRouter as Router, Route, Routes,} from 'react-router-dom';
import AdminWrapper from './layouts/AdminWrapper';
import {UserContext} from './context/UserContext';
import Register from './pages/Auth/Register'
import {ToastContainer} from "react-toastify";
import Nodes from './pages/Nodes/Nodes'
import Campaigns from './pages/Campaigns/Campaigns';
import Settings from './pages/Settings/Settings';
import Campaign from './pages/Campaigns/Campaign';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import PublicWrapper from './layouts/PublicWrapper';

function App() {

    const darkTheme = createTheme({
        palette: {
          mode: 'dark',
        },
      });

    const [user, setUser] = useState(null)
    useEffect(() => {
        const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false
        const access_token = localStorage.getItem('access_token')
        const userInfo = user ? {
            ...user,
            token: access_token
        } : false
        setUser(userInfo)
        setUser(user)
    }, [])
    const loginUser = (data) => {
        localStorage.setItem('user', JSON.stringify(data.user))
        localStorage.setItem('access_token', data.access_token)
        setUser(data.user)
    }

    const resetUser = () => {
        setUser(false)
        localStorage.removeItem('user')
        localStorage.removeItem('access_token')
    }

    return (
    <ThemeProvider theme={darkTheme}>
    <CssBaseline />
        <UserContext.Provider value={{user, setUser: loginUser, resetUser}}>
            <Router>
                <Routes>
                    <Route path="/" element={<PublicWrapper><Login/></PublicWrapper>}/>
                    <Route path="/register" element={<PublicWrapper><Register/></PublicWrapper>}/>
                    <Route path="/forgot" element={<PublicWrapper><Forgot/></PublicWrapper>}/>
                    <Route path="/login" element={<PublicWrapper><Login/></PublicWrapper>}/>
                    <Route path="/confirm/:token" element={<PublicWrapper><Confirm/></PublicWrapper>}/>

                    <Route path="/nodes" element={<AdminWrapper><Nodes/></AdminWrapper>}/>
                    <Route path="/campaigns" element={<AdminWrapper><Campaigns/></AdminWrapper>}/>
                    <Route path="/settings" element={<AdminWrapper><Settings/></AdminWrapper>}/>
                    <Route path="/campaigns/:id" element={<AdminWrapper><Campaign/></AdminWrapper>}/>
                    <Route path="*" element={<div>404 not found</div>}/>
                </Routes>
            </Router>
            <ToastContainer />
        </UserContext.Provider>
        </ThemeProvider>
    );

}

export default App;
