import * as React from 'react';
import { useContext } from 'react';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { UserContext } from '../../context/UserContext';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import './Menu.css';
import TimeToLeaveIcon from '@mui/icons-material/TimeToLeave';
import PersonIcon from '@mui/icons-material/Person';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import LogoutIcon from '@mui/icons-material/Logout';
import PostAddIcon from '@mui/icons-material/PostAdd';
import LogOutPanel from './LogOutPanel'
import WidgetsIcon from '@mui/icons-material/Widgets';
import SettingsIcon from '@mui/icons-material/Settings';

const menuList = [
    { label: 'Nodes', path: '/nodes', icon: <PostAddIcon fontSize='small'/> },
    { label: 'Campaigns', path: '/campaigns', icon: <WidgetsIcon fontSize='small'/> },
    { label: 'Settings', path: '/settings', icon: <SettingsIcon fontSize='small'/> }
]
const Menu = () => {
    const navigate = useNavigate()
    const { resetUser } = useContext(UserContext);
    const [openLogOutPanel, setOpenLogOutPanel] = useState(false)
    const handleOpenLogOutPanel = () => {
        setOpenLogOutPanel(!openLogOutPanel)
    }

    return (<Box className='menuOverlead'
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
    >
        <label
            orientation="vertical"
            variant="scrollable"
            aria-label="Vertical tabs example"
        ></label>
        <Box className='menuList'>
        <Box className='navigateBar'>
            {menuList.map((menuItem) => (
                <Link underline='none' key={menuItem.path}
                    onClick={() => {
                        navigate(menuItem.path)
                    }}>
                    <Button color={menuItem.path === window.location.pathname ? 'secondary' : 'primary'}
                        className='menuButtons'><Box className='icon'>{menuItem.icon}</Box><Box className='link'>{menuItem.label}</Box></Button>
                </Link>
            ))}
            </Box>
            <Box className='navigateBar'>
            <Button className='menuButtons' onClick={handleOpenLogOutPanel}><Box className='icon'><LogoutIcon fontSize='small'/></Box><Box className='link'>logout</Box></Button>
            </Box>
            {openLogOutPanel ? <LogOutPanel
            handleOpenLogOutPanel={handleOpenLogOutPanel}
            resetUser={resetUser}
            /> : null}
        </Box>
    </Box>);
}

export default Menu
