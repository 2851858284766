import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { Button, CssBaseline, TextField, Grid, Box, Typography, Container } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import { UserContext } from '../../context/UserContext';
import * as Validator from "../../utils/validation";
import Link from "../../parts/Link";
import { AUTH_API_PATH } from "./apiPath";
import api from "../../utils/api";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
        </Typography>
    );
}


export default function Forgot() {
    const navigate = useNavigate()
    const [isValidEmail, setValidEmail] = useState(true)
    const { user } = useContext(UserContext)

    useEffect(() => {
        if (user) {
            navigate('/cars')
        }
    }, [user])

    const handleSubmit = (e) => {
        e.preventDefault()
        const email = e.target.email.value;
        if (isValidEmail) {
            api.post('/forgot-password', {
                email
            }).then((data) => {
                navigate('/login')
                toast.success("Succes!")
            }).catch((error) => {
                toast.error(error.response.data.message);
            })
        }
    };
    const checkValidEmail = ({ target }) => setValidEmail(Validator.isValidEmail(target.value));

    return (
        <>
            <div className='parentDiv'>
            <img className='authIcon' src='/images/armenia-logo-min-original.png'></img>
                <div className='childDiv'>
                        <Container component="main" maxWidth="xs">
                            <Box className='parentForm'>
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                    <TextField
                                        margin="normal"
                                        required
                                        fullWidth
                                        id="email"
                                        label="E-mail"
                                        name="email"
                                        autoComplete="email"
                                        error={!isValidEmail}
                                        onBlur={(e) => checkValidEmail(e)}
                                    />
                                    <div className='forgotLinkDiv'>
                                        <Grid className='parentGrid' container>
                                            <Grid className='childGrid'
                                                item xs>
                                                <Typography className='forgotText' href="src/pages/Auth/Forgot#" variant="body2">
                                                    Enter your email and we'll send you a link to get back into your
                                                    account.
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <Link sx={{color: 'white'}} to='/login' text='Login'
                                    />
                                    <Button className='forgotButton'
                                        color='inherit'
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                        Send
                                    </Button>
                                </Box>
                            </Box>
                            <Copyright />
                        </Container>
                </div>
            </div>
            <ToastContainer />
        </>
    );
}