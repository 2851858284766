import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import {UserContext} from '../../context/UserContext';
import * as Validator from "../../utils/validation";
import {AUTH_API_PATH} from "./apiPath";
import api from "../../utils/api";


function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} />
    );
}


export default function SignIn() {
    const [isValidPassword, setValidPassword] = useState(true)
    const [isValidConfirmPassword, setValidConfirmPassword] = useState(true)

    const password = useRef(null);
    const confirmPassword = useRef(null);

    const navigate = useNavigate()
    const {token} = useParams()

    const {user} = useContext(UserContext)

    useEffect(()=>{
        if (user) {
            navigate('/cars')
        }
    },[user])

    const handleSubmit = (e) => {
        e.preventDefault()
        if (isValidPassword && isValidConfirmPassword) {
            api.post('/reset-password', {
                "password": password.current.value,
                "confirmPassword": password.current.value,
                "token": token || ''
            }).then(() => {
                navigate('/cars')
                toast.success("Success!")
            }).catch((error) => {
                toast.error(error.message);
            })
        }
    };
    const checkValidPassword = ({target})  => setValidPassword(Validator.lengthMoreThan(target.value, 7));
    const checkValidConfirmPassword = ()  => setValidConfirmPassword(password.current.value === confirmPassword.current.value);

    return (
        <>
            <div className='parentDiv'>
            <img className='authIcon' src='/images/armenia-logo-min-original.png'></img>
                <div className='childDiv'>
                        <Container component="main" maxWidth="xs">
                            <Box className='parentForm'>       
                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
                                    <TextField
                                        margin="normal"
                                        inputRef={password}
                                        required
                                        fullWidth
                                        id="pass"
                                        label="New Password"
                                        name="pass"
                                        autoComplete="pass"
                                        autoFocus
                                        error={!isValidPassword}
                                        onBlur={(event) => checkValidPassword(event)}
                                    />
                                    <TextField
                                        margin="normal"
                                        inputRef={confirmPassword}
                                        required
                                        fullWidth
                                        name="confirm"
                                        label="Confirm Password"
                                        type="confirm"
                                        id="confirm"
                                        autoComplete="current-password"
                                        error={!isValidConfirmPassword}
                                        onBlur={checkValidConfirmPassword}

                                    />
                                    <Button className='confirmButton'
                                        color='inherit'
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                    >
                                        Confirm
                                    </Button>
                                    <Link sx={{color: 'white'}} to='/forgot' text='Forgot'/>
                                </Box>
                            </Box>
                            <Copyright/>
                        </Container>
                </div>
            </div>
            <ToastContainer/>
        </>
    );
}