import * as React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import './SettingsModal.css';
import { useState } from "react";
import * as Validator from "../../utils/validation";
import Switch from '@mui/material/Switch';
import { isEditable } from '@testing-library/user-event/dist/utils';

export default function SettingsModal(props) {

    const [isValidNumber, setValidNumber] = useState(true)
    const [type, setType] = useState(props.settingRow?.type)

    console.log(props.settingRows)

    return <Box className='overlead'>

        <Box className='form' component="form" onSubmit={props.submit}>

            <Button className='modalCloseButton' color='inherit' onClick={props.create} variant="text">
                X
            </Button>
            <Typography variant='h5'>{props.title}</Typography>
            <Box className='inputs'>
            <Box className='inputsParent'>
            <Box className='formControlChild'>
                <Select
                                    size='small'
                                    name='type'
                                    defaultValue={props.settingRow?.type}
                                    placeholder="Type"
                                    onChange={({ target }) => setType(target.value)}
                                    value={type}
                                    sx={{
                                        m: 1,
                                        borderRadius: '5px',
                                        width: '40%',
                                        margin: '0 0 20px 0'
                                    }}
                                >
                                    <MenuItem value={1}>DMS</MenuItem>
                                    <MenuItem value={2}>ACRA</MenuItem>
                                    <MenuItem value={3}>NORK</MenuItem>
                                </Select>
                </Box>
                <Box className='formControlChild'>
                <TextField
                                size="small"
                                name='name'
                                defaultValue={props.settingRow?.name}
                                variant="outlined"
                                placeholder='Name'
                                label='Name'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    borderRadius: '5px',
                                    width: '40%',
                                    margin: '0 0 0 0'
                                }}
                            ></TextField>
                            <TextField
                                size="small"
                                name='url'
                                defaultValue={props.settingRow?.url}
                                variant="outlined"
                                placeholder='URL'
                                label='URL'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    borderRadius: '5px',
                                    width: '40%',
                                    margin: '0 0 0 0'
                                }}
                            ></TextField>
                </Box>
            </Box>
            <Box className='inputsParent'>
            {type == 1 ? 
                <Box className='formControlChild'>
                <TextField
                    size="small"
                    name='key'
                    defaultValue={props.settingRow?.key}
                    variant="outlined"
                    placeholder='Key'
                    label='Key'
                    margin="normal"
                    sx={{
                        m: 1,
                        borderRadius: '5px',
                        width: '40%',
                        margin: '0 0 0 0'
                    }}
                ></TextField>
                </Box>
                : <><Box className='formControlChild'>
                            <TextField
                                size="small"
                                name='username'
                                    defaultValue={props.settingRow?.additionalData?.username}
                                variant="outlined"
                                placeholder='Username'
                                label='Username'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    borderRadius: '5px',
                                    width: '40%',
                                    margin: '0 0 0 0'
                                }}
                            ></TextField>
                            <TextField
                                size="small"
                                name='password'
                                defaultValue={props.settingRow?.additionalData?.password}
                                variant="outlined"
                                placeholder='Password'
                                label='Password'
                                margin="normal"
                                sx={{
                                    m: 1,
                                    borderRadius: '5px',
                                    width: '40%',
                                    margin: '0 0 0 0'
                                }}
                            ></TextField>
                        </Box>
                            <Box className='formControlChild'>
                                <TextField
                                    size="small"
                                    name={type == 2 ? 'reqType' : 'loginAction'}
                                    defaultValue={type == 2 ? props.settingRow?.additionalData?.reqType : props.settingRow?.additionalData?.loginAction}
                                    variant="outlined"
                                    placeholder={type == 2 ? 'Req Type' : 'Login Action'}
                                    label={type == 2 ? 'Req Type' : 'Login Action'}
                                    margin="normal"
                                    sx={{
                                        m: 1,
                                        borderRadius: '5px',
                                        width: '40%',
                                        margin: '40px 0 0 0'
                                    }}
                                ></TextField>
                                {type == 3 ? <TextField
                                    size="small"
                                    name='getDataAction'
                                    defaultValue={props.settingRow?.additionalData?.getDataAction}
                                    variant="outlined"
                                    placeholder='Get Data Action'
                                    label='Get Data Action'
                                    margin="normal"
                                    sx={{
                                        m: 1,
                                        borderRadius: '5px',
                                        width: '40%',
                                        margin: '40px 0 0 0'
                                    }}
                                ></TextField> : null}
                            </Box></> }

                </Box>
            </Box>
            <Box className='tempsParent'>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                <Button color='inherit' type='submit'
                    sx={{ width: '30%', margin: '10px' }} variant='outlined'
                >{props.button}</Button>
            </Box>
        </Box>
    </Box>
}