import { useContext, useEffect, useState } from 'react';
import Header from '../parts/Header';
import Menu from '../parts/Menu/Menu'
import './AdminWrapper.css'
import { UserContext } from '../context/UserContext';
import { useNavigate } from 'react-router-dom';


const AdminWrapper = (props) => {
    const navigate = useNavigate()
    const userContext = useContext(UserContext)
    const [isOpen, setIsOpen] = useState(true)

    useEffect(() => {
        if (userContext.user === false) {
            return navigate('/register')
        }
    }, [userContext.user]);

    return (
        <>
            <Header handleTogglePanel={() => setIsOpen(!isOpen)} />
            <main>
                {isOpen && <Menu setIsOpen={setIsOpen} handleTogglePanel={() => setIsOpen(!isOpen)} />}
                <div className='page'>
                    {props.children}
                </div>
            </main>
        </>
    );
}

export default AdminWrapper;